import React, { useState } from 'react';
import './odds-calculator.scss';

function OddsCalculator() {
  const [ticketPrice, setTicketPrice] = useState('');
  const [prizeValue, setPrizeValue] = useState('');
  const [ticketsSold, setTicketsSold] = useState('');
  const [ownedTickets, setOwnedTickets] = useState('');
  const [trueValue, setTrueValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!ticketPrice || !prizeValue || !ticketsSold || !ownedTickets) {
      alert('Please fill in all fields.');
      return;
    }

    const totalTickets = 330;
    const remainingTickets = totalTickets - ticketsSold;
    const winProbability = ownedTickets / remainingTickets;
    const expectedValue = winProbability * prizeValue;
    const trueTicketValue = expectedValue - ticketPrice;

    function calculateOdds(ticketPrice, prizeValue, totalTickets, ticketsSold, daysInMonth, numTicketsOwned) {
        const totalDrawings = daysInMonth * 2; 
      
        const singleDrawOdds = numTicketsOwned / totalTickets;
      
        const probNotWinningOneTicket = 1 - singleDrawOdds; 
      
        const effectiveOddsWinning = 1 - Math.pow(probNotWinningOneTicket, totalDrawings);
      
        const expectedValue = prizeValue * effectiveOddsWinning;
      
        const netGainLoss = expectedValue - (ticketPrice * numTicketsOwned);
      
        const results = `
          Expected Value: $${expectedValue.toFixed(2)}<br/>
          Net Gain/Loss per NFT: $${netGainLoss.toFixed(2)} <br/>
          Odds of Winning with at Least One of ${numTicketsOwned} NFT: ${singleDrawOdds.toFixed(4)} (1 in ${(1 / singleDrawOdds).toFixed(2)})<br/>
        `;
      
        return results;
      }

    setTrueValue(calculateOdds(ticketPrice, prizeValue, totalTickets, ticketsSold, 30, ownedTickets));
  };

  return (
    <div className="odds-calculator">
      <h2>Odds Calculator</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="ticketPrice">NFT Price: </label>
          <input
            type="number"
            id="ticketPrice"
            value={ticketPrice}
            onChange={(e) => setTicketPrice(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="prizeValue">Total Prize Value: </label>
          <input
            type="number"
            id="prizeValue"
            value={prizeValue}
            onChange={(e) => setPrizeValue(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="ticketsSold">Total number of NFTs that have been Minted (<a href="https://deadrare.io/collection/year-of-the-dragon?tab=rarities">Deadrare's rarity page for Dragon</a>): </label>
          <input
            type="number"
            id="ticketsSold"
            value={ticketsSold}
            onChange={(e) => setTicketsSold(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="ownedTickets">The number of NFTs You Own: </label>
          <input
            type="number"
            id="ownedTickets"
            value={ownedTickets}
            onChange={(e) => setOwnedTickets(e.target.value)}
          />
        </div>
        <button type="submit">Calculate</button>
      </form>
      {trueValue !== '' && (
        <div className="result" dangerouslySetInnerHTML={{ __html: trueValue }} />
      )}
    </div>
  );
}

export default OddsCalculator;