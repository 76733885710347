import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import './Header.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  faXTwitter } from "@fortawesome/free-brands-svg-icons"
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


function Header({ message }) {
  const [navBarShow, setNavbarShow] = useState(false);

  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <header className={'header'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Navbar expand={false}>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} onClick={() => setNavbarShow(!navBarShow)} />
        <Navbar.Offcanvas
          show={navBarShow}
          id={`offcanvasNavbar-expand-${false}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
          placement="start"
        >
          <Offcanvas.Header closeButton onClick={() => setNavbarShow(false)}>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {!isHomePage && <Nav.Link onClick={() => setNavbarShow(false)} href="#/">Home</Nav.Link>}
              {/* {!isHomePage && <hr />} */}
              {/* {!isHomePage && <Nav.Link onClick={() => setNavbarShow(false)} href="/">Home</Nav.Link>} */}
              {location.pathname !== "/random-date-picker" && 
                <Nav.Link onClick={() => setNavbarShow(false)} href="#/random-date-picker">Random Date Picker</Nav.Link>}
              
              {location.pathname !== "/odds-calculator" && 
                <Nav.Link onClick={() => setNavbarShow(false)} href="#/odds-calculator">Odds Calculator</Nav.Link>}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>      
      <div className={'header-text'} dangerouslySetInnerHTML={{ __html: message }}>
      </div>
      <a href="https://twitter.com/alph_dragon" className="text-decoration-none">
        <FontAwesomeIcon className={'xTwitter'} icon={faXTwitter} />
      </a>
    </header>
  );
}

export default Header;
