import React, { useState } from 'react';
import Header from '../Components/Header';
import ImageShuffler from '../Components/ImageShuffler';
import CountdownTimer from '../Components/CountdownTimer';

function Home() {
  const [timerCompleted, setTimerCompleted] = useState(false);
  const [headerMessage, setHeaderMessage] = useState('The Dragon Awakens');
  const targetDate = new Date('2024-03-05T17:00:00Z');
  targetDate.setDate(targetDate.getDate() + 8);

  const onCompleted = () => {
    setTimerCompleted(true);
    setHeaderMessage('On 6/9/2024 the LP was locked for 12 years until the next year of the dragon. <br/> 5 Million $DRAGON tokens were burned on 6/9/2024.<br/><a href="https://deadrare.io/collection/year-of-the-dragon" target=_blank><img>Year of the Dragon</a>');
  };

  return (
    <div className="app">
      <Header message={headerMessage} />
      {!timerCompleted && (
        <footer>
          <CountdownTimer date={targetDate} onCompleted={onCompleted} />
        </footer>
      )}
    </div>
  );
}

export default Home;
