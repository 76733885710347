import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './image-shuffler.scss';

function ImageShuffler() {
  const [images, setImages] = useState([]); // Start empty, no initial image
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isShuffling, setIsShuffling] = useState(false);

  const timeout = 500; 
  const minTimeout = 5000; 
  const maxTimeout = 8000;
  const numberOfImages = 331;

  
  const getRandomTimeout = () => {
    return Math.floor(Math.random() * (maxTimeout - minTimeout + 1)) + minTimeout;
  };

  
  useEffect(() => {
    const imagePaths = [];
    for (let i = -1; i < numberOfImages; i++) {
      imagePaths.push(`/DragonNft/${i}.webp`);
    }

    const initialImage = imagePaths[0]; 
    const remainingImages = imagePaths.slice(1); 
    setImages([initialImage]); 

    const loadImage = (path) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = path;
        image.onload = resolve;
        image.onerror = reject;
      });
    };

    const preloadImages = async () => {
      try {
        const loadingPromises = remainingImages.map((path) => 
        loadImage(path).then(() => {
          setImagesLoaded((prevCount) => prevCount + 1);
        })
      );
        await Promise.all(loadingPromises);
        setImages(imagePaths);
        setIsLoading(false);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    preloadImages();

  }, []);

  const shuffleImages = () => {
    setIsShuffling(true);

    
    const shuffledImages = [...images]; 
    for (let i = shuffledImages.length - 1; i > 0; i--) {
      let randomIndex = Math.floor(Math.random() * (i + 1));
      [shuffledImages[i], shuffledImages[randomIndex]] = [shuffledImages[randomIndex], shuffledImages[i]];
    }
    setImages(shuffledImages); 

    
    let shuffleInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 50); 

    
    setTimeout(() => {
      clearInterval(shuffleInterval);
      handleShuffleComplete();
    }, getRandomTimeout()); 
  };

  const handleShuffleComplete = () => {
    setIsShuffling(false);
    const randomIndex = Math.floor(Math.random() * images.length -1) + 1;
    setCurrentIndex(randomIndex);
  };

  return (
    <div className="image-container">
      {isLoading && (
        <ProgressBar
          animated
          now={(imagesLoaded / numberOfImages) * 100}
          label={`Loading Images... (${imagesLoaded} / ${numberOfImages})`}
        />
      )}

      {!isLoading && images.length > 0 && ( // Only display image after loading
        <img
          src={images[currentIndex]}
          onClick={shuffleImages} 
          alt="Dragon NFT"
        />
      )}
    </div>
  );
}

export default ImageShuffler;
