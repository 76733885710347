
import React from 'react';
import Countdown from 'react-countdown';
import { useEffect } from 'react';

const Completionist = ({ onCompleted }) => {
  // Trigger the onCompleted event when this component is rendered
  useEffect(() => {
    onCompleted();
  }, [onCompleted]);

  return <div style={{textAlign: 'center', marginTop: '50vh', fontSize: '24px'}}>Your custom message here</div>;
};

const renderer = ({ days, hours, minutes, seconds, completed }, onCompleted) => {
  if (completed) {
    // Render a completed state
    return <Completionist onCompleted={onCompleted} />;
  } else {
    // Render a countdown
    return <span>{days} days {hours} hours {minutes} minutes {seconds} seconds</span>;
  }
};

function CountdownTimer({date, onCompleted}) {
  const targetDate = new Date(date);
  return <Countdown date={targetDate} renderer={(props) => renderer(props, onCompleted)} />;
}

export default CountdownTimer;
