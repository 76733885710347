import './App.css';
import React from "react";
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import RandomDatePicker from './pages/randomDatePicker';
import OddsCalculatorPage from './pages/oddsCalculatorPage';

function App() {

  return (
    <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/random-date-picker" element={<RandomDatePicker />} />
            <Route path="/odds-calculator" element={<OddsCalculatorPage />} />
        </Routes>
    </Router>
  );
}

export default App;
