import React, { useState } from 'react';
import Header from '../Components/Header';
import ImageShuffler from '../Components/ImageShuffler';
import CountdownTimer from '../Components/CountdownTimer';

function RandomDatePicker() {
  const [timerCompleted, setTimerCompleted] = useState(false);
  const [headerMessage, setHeaderMessage] = useState('The Dragon Awakens');
  const targetDate = new Date('2024-03-05T17:00:00Z');
  targetDate.setDate(targetDate.getDate() + 8);

  const onCompleted = () => {
    setTimerCompleted(true);
    setHeaderMessage('NFTs are used for random drawings for NFTs, $DRAGON, etc. The NFT with the date for $DRAGON ATH the end of the year recieves an ALPH award. (currently 58 ALPH locked until 1/28)<br/>  Be sure to watch our X account! <br/><a href="https://deadrare.io/collection/year-of-the-dragon" target=_blank><img>Year of the Dragon</a>');
  };

  return (
    <div className="app">
      <Header message={headerMessage} />
      <div className="imageShufflerContainer">
        <ImageShuffler />
      </div>
      {!timerCompleted && (
        <footer>
          <CountdownTimer date={targetDate} onCompleted={onCompleted} />
        </footer>
      )}
    </div>
  );
}

export default RandomDatePicker;
